/**
 * A very simple static class for retrieving
 * a users country code (ISO 3166-1 alpha-2)
 * from `cloudfront-viewer-country` cookie
 */

export class Geolocate {
    static getCountryCode() {
        const defaultCountry = 'US'
        if (process.server) {
            console.warn(`No cookie data during pre-rendering. Using default (${defaultCountry}) ..`)
            return defaultCountry
        }
        const cookieKey = 'cloudfront-viewer-country'
        // default to US if the `cloudfront-viewer-country` header is not present
        const cookieExists = document.cookie.indexOf(cookieKey) !== -1
        if (!cookieExists) {
            return defaultCountry
        }

        const cookieData = `; ${document.cookie}`
        const parts = cookieData.split(`; ${cookieKey}=`)
        if (!!parts && parts.length === 2) {
            const viewerCountry = parts.pop().split(';').shift()
            if (!viewerCountry) return defaultCountry
            return viewerCountry
        }
        return defaultCountry
    }
}
