/**
 * A very simple static class for setting and retrieving
 * a users locale taking into account what is supported
 */

export class I18n {
    static supported = ['en', 'fr', 'it', 'de', 'es', 'es-XL', 'es-419', 'pt-BR', 'pt-br'] as const

    static getLocaleSlug(slug: string, locale: string) {
        if (locale && locale !== 'en') {
            const slugLocale = `-${locale.toLowerCase()}`
            if (!slug.endsWith(slugLocale)) {
                return `${slug}${slugLocale}`
            }
        }
        return slug
    }

    static getLocaleNameTag(locale: I18n.Locale) {
        switch (locale) {
        case 'fr':
            return 'LANGUAGE_MENU.FR'
        case 'it':
            return 'LANGUAGE_MENU.IT'
        case 'de':
            return 'LANGUAGE_MENU.DE'
        case 'es':
            return 'LANGUAGE_MENU.ES'
        case 'es-XL':
        case 'es-419':
            return 'LANGUAGE_MENU.ES-XL'
        case 'pt-br':
            return 'LANGUAGE_MENU.PT-BR'
        default:
            return 'LANGUAGE_MENU.EN'
        }
    }

    static getLocaleForListrak(locale: string) {
        switch (locale) {
        case 'es':
            return 'es-es'
        case 'es-XL':
        case 'es-419':
            return 'es-la'
        default:
            return locale
        }
    }

    private static getPreferredDeviceLocale(): I18n.Locale {
        const deviceLocales = navigator?.languages ?? []

        for (let i = 0; i < deviceLocales.length; i++) {
            if (this.isSupported(deviceLocales[i])) {
                return deviceLocales[i] as I18n.Locale
            }
        }

        return this.supported[0]
    }

    public static isSupported(string: string): boolean {
        return Object.values(this.supported).includes(string as I18n.Locale)
    }

    static getLocaleFromDomain(domain: string) {
        switch (domain) {
        case 'www.jackboxgames.fr':
            return 'fr'
        case 'www.jackboxgames.it':
            return 'it'
        case 'www.jackboxgames.de':
            return 'de'
        case 'www.jackboxgames.es':
            return 'es'
        case 'www.jackboxgames.mx':
            return 'es-XL'
        }
        return null
    }

    static getDomainFromLocale(localeCode: string) {
        if (window?.location.hostname === 'localhost') {
            return `localhost:${window.location.port}`
        }
        switch (localeCode.toLowerCase()) {
        case 'fr':
            return 'www.jackboxgames.fr'
        case 'it':
            return 'www.jackboxgames.it'
        case 'de':
            return 'www.jackboxgames.de'
        case 'es':
            return 'www.jackboxgames.es'
        case 'es-xl':
            return 'www.jackboxgames.mx'
        default: // en, pt-BR
            return 'www.jackboxgames.com'
        }
    }
}

export namespace I18n {
    export type Locale = typeof I18n.supported[number]
    export type Messages<T> = Partial<Record<Locale, T>>
}
