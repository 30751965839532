import { I18n } from '$services/i18n'

export default defineNuxtRouteMiddleware((next, _prev) => {
    const { $i18n } = useNuxtApp()
    const localeRoute = useLocaleRoute()

    if (next.query.lang?.length > 0) {
        const nextLocale = next.query.lang as I18n.Locale

        if (I18n.supported.includes(nextLocale)) {
            void $i18n.setLocale(nextLocale)

            const nextRoute = localeRoute(next.path, nextLocale)
            nextRoute.query = { ...next.query, lang: undefined }

            console.debug('Found Locale query; Redirecting to', nextRoute)
            return navigateTo(nextRoute)
        }
    }
})
